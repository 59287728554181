<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12" :class="{'md4': hasProyections}">
        <va-card :title="$t('mti.view')">
          <actions
            slot="actions"
            crud-links="mti"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          >
            <template v-if="mti.id != null">
              <va-popover
                :message="$t('tables.actions.export', {format: 'Excel'})"
                placement="down"
              >
                <va-button
                  flat
                  small
                  color="success"
                  icon="fa fa-file-excel-o"
                  @click.prevent="prepareDownload('xlsx')"
                />
              </va-popover>
            </template>
          </actions>
          <text-list :title="$t('tables.headings.name')">
            {{ mti.name }}
          </text-list>
          <text-list
            v-if="hasNested(mti, 'district.country.region')"
            :condition="currentUser.can('Regions', 'view')"
            :title="$t('tables.headings.region')"
            :label="$t(mti.district.country.region.name)"
            :to="{ name: 'regionsView', params: {id: mti.district.country.region.id}}"
          />
          <text-list
            v-if="hasNested(mti, 'district.country')"
            :condition="currentUser.can('Countries', 'view')"
            :title="$t('tables.headings.country')"
            :label="$t(mti.district.country.name)"
            :to="{ name: 'countriesView', params: {id: mti.district.country.id}}"
          />
          <text-list
            v-if="mti.hasOwnProperty('district')"
            :condition="currentUser.can('Districts', 'view')"
            :title="$t('tables.headings.district')"
            :label="$t(mti.district.name)"
            :to="{ name: 'districtsView', params: {id: mti.district.id}}"
          />
          <text-list
            v-if="mti.hasOwnProperty('team')"
            :condition="currentUser.can('Teams', 'view')"
            :title="$t('tables.headings.team')"
            :label="mti.team.code"
            :to="{ name: 'teamsView', params: {id: mti.team.id}}"
          />
          <text-list
            v-if="mti.hasOwnProperty('minister')"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.minister')"
            :label="mti.minister.name"
            :to="{ name: 'usersView', params: {id: mti.minister.id}}"
          />
          <text-list
            v-if="mti.hasOwnProperty('minister')"
            :title="$t('tables.headings.minister_email')"
          >
            {{ mti.minister.email }}
          </text-list>
          <text-list :title="$t('mti.inputs.hasMother')">
            {{ $t(hasMother ? 'layout.yes' : 'layout.no') }}
          </text-list>
          <text-list
            v-show="hasMother"
            :title="$t('mti.inputs.motherMinisterInput')"
          >
            {{ mti.mother_church_minister }}
          </text-list>
          <text-list
            v-show="hasMother"
            :title="$t('mti.inputs.motherChurchInput')"
          >
            {{ mti.mother_church }}
          </text-list>
          <text-list
            v-show="mti.updated_at"
            :title="$t('tables.headings.last_modified')"
          >
            {{ mti.updated_at | date }}
          </text-list>
        </va-card>
      </div>
      <div class="flex xs12 md8" v-if="hasProyections">
        <va-card :title="$t('mti.preaching_points')">
          <remote-table
            class="table-hover table-striped"
            :columns="tableFields"
            :data="tableData"
            :loading="loading"
            :with-search="false"
            :with-pager="false"
            :top-options="topActions"
            :action-options="tableActions"
            crud-links="reports"
            controller="Proyections"
          />
        </va-card>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { hasOwnNestedProperty } from '@/services/utils'
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')

export default {
  name: 'mti-view',
  components: {
    RemoteTable, Actions, TextList,
  },
  data () {
    return {
      mti: {},
      tableData: [],
      error: false,
      loading: false,
      actions: ['index', 'new', 'edit', 'delete'],
      topActions: [],
      tableActions: ['view'],
      serverParams: {
        queries: '',
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    hasMother () {
      return this.mti && (this.mti.mother_church || this.mti.mother_church_minister)
    },
    hasProyections () {
      return this.mti.proyections ? this.mti.proyections.length > 0 : false
    },
    tableFields () {
      return [
        {
          name: 'preaching_date',
          title: this.$t('tables.headings.date'),
          callback: this.dateLabel,
          sortField: 'preaching_date',
        },
        {
          name: 'type_name',
          title: this.$t('tables.headings.name'),
          sortField: 'type_name',
        },
        {
          name: 'type_asistance',
          title: this.$t('tables.headings.assistance'),
          sortField: 'type_asistance',
        },
        {
          name: '__slot:actions',
          visible: this.tableData.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    dateLabel (date) {
      if (!date) return ''
      return this.$date.format(date, 'dd/MM/yyyy')
    },
    hasNested (obj, property) {
      return hasOwnNestedProperty(obj, property)
    },
    routeBuilder (id) {
      return `mti/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const mtiId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(mtiId))
      } catch (err) {
        // console.log('Error fetching mti data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.mti = u.data.data
      if (this.mti.proyections) {
        this.tableData = this.mti.proyections
      }
    },
    async prepareDownload (format) {
      const prepareRoute = this.routeBuilder(this.mti.id) + '?export=' + format
      const downloadRoute = 'mti/download/'

      return this.downloadFile(prepareRoute, downloadRoute)
    },
    async downloadFile (prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.get(prepareRoute)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
